<template>
  <router-link :to="`/webinar/${webinar.id}`">
    <el-row :gutter="20" class="margin-bottom-4 webinar-listing" :class="{past: !webinar.upcoming}">
      <el-col :xs="24" :sm="6">
        <DoroImage :src="image(webinar.id, webinar.mainImage)"/>
      </el-col>
      <el-col :xs="24" :sm="18">
        <p class="webinar-hint">
          {{calculatedDate}}
        </p>
        <h3 class="webinar-title">{{webinar.title}}</h3>
        <p class="webinar-desc">{{webinar.summary}}</p>
      </el-col>
    </el-row>
  </router-link>
</template>



<script>
import {Component, Vue} from "vue-property-decorator";
import DoroImage from "@/components/DoroImage"

@Component({
  components: {DoroImage},
  props:  {
    'webinar': Object,
  }
})

export default class WebinarListing extends Vue {
  image(webinarId, imageName) {return require(`@/assets/webinars/${webinarId}/${imageName}`)}

  get calculatedDate() {
    return this.webinar.dateTime.toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: 'numeric'})
  }
}
</script>

<style lang="scss">
.webinar-listing {

  .webinar-desc {
    font-weight: 200;
    margin: 0;
    color: #000;
  }

  .webinar-hint {
    color: #737373;
    font-weight: 400;
    font-size: 15px;
    margin: 0.5ch 0;

  }

  .past {
    opacity: 0.6;
  }

  .webinar-title {
    margin: 0.2ch 0;
    font-weight: 400;
    font-size: 22px;
    color: #000
  }

}
</style>
