<template>
  <div class="main-limiter">
    <H1>Webinars</H1>
    <section v-if="webinars.filter(w => w.upcoming).length > 0">
      <H3>Upcoming webinars</H3>
      <WebinarListing
          v-for="w in webinars.filter(w => w.upcoming)"
          :key="w.id"
          :webinar="w"
      />
    </section>
    <section>
      <H3>Past webinars</H3>
      <WebinarListing
          v-for="w in webinars.filter(w => !w.upcoming)"
          :key="w.id"
          :webinar="w"
      />
    </section>

  </div>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import WebinarListing from "@/components/WebinarListing";

@Component({
  components: {WebinarListing},
  props: {
    'webinars': Array
  },
  metaInfo() {
    return {
      title: 'Webinars',
    }
  }
})

export default class WebinarList extends Vue {}
</script>
